import { useTranslation } from "react-i18next";
import { Watermark } from "../../template";
import PngExportable from "../PngExportable";
import Table from "../Table";
import Day from "../Day";
import Unit from "../Unit";
import Location from "../Location";
import Hour from "../Hour";
import TableExportable from "../TableExportable";
import CreatedBy from "../CreatedBy";

const PermissionReport = ({
	start,
	end,
	day,
	unit,
	template,
	location,
	hour,
	table,
	createdby,
}) => {
	const { t } = useTranslation();
	return (
		<div>
			<PngExportable
				title={t(`report_title.checklist_template`, { start, end })}
			>
				<div className="relative">
					<Watermark />
					<Table
						data={template}
						tPath="const.checklist_status"
						columns={["normal", "issue", "inprogress", "completed", "total"]}
					/>
				</div>
				<div className="mt-4" style={{ height: 600 }}>
					<Unit
						data={template.map(
							({ normal, issue, inprogress, completed, name }) => {
								return {
									name,
									children: [
										{
											name: t(`const.checklist_status.normal`),
											total: normal,
											color: "#008000",
										},
										{
											name: t(`const.checklist_status.issue`),
											total: issue,
											color: "#D90429",
										},
										{
											name: t(`const.checklist_status.inprogress`),
											total: inprogress,
											color: "#FCA311",
										},
										{
											name: t(`const.checklist_status.completed`),
											total: completed,
											color: "#1894c5",
										},
									],
								};
							}
						)}
					></Unit>
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.checklist_day`, { start, end })}>
				<div style={{ height: 500 }} className="relative">
					<Watermark />
					<Day
						data={day.map(
							({ _id, normal, issue, inprogress, completed, total }) => ({
								_id,
								[t(`const.checklist_status.normal`)]: normal,
								[t(`const.checklist_status.issue`)]: issue,
								[t(`const.checklist_status.inprogress`)]: inprogress,
								[t(`const.checklist_status.completed`)]: completed,
								[t(`total`)]: total,
							})
						)}
						statuses={[
							{ status: "normal", color: "#008000" },
							{ status: "issue", color: "#D90429" },
							{ status: "inprogress", color: "#FCA311" },
							{ status: "completed", color: "#1894c5" },
						]}
						tPath="const.checklist_status"
					></Day>
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.checklist_unit`, { start, end })}>
				<div className="relative">
					<Watermark />
					<Table
						data={unit}
						tPath="const.checklist_status"
						columns={["normal", "issue", "inprogress", "completed", "total"]}
					/>
				</div>
				<div className="mt-4" style={{ height: 600 }}>
					<Unit
						data={unit.map(({ normal, issue, inprogress, completed, name }) => {
							return {
								name,
								children: [
									{
										name: t(`const.checklist_status.normal`),
										total: normal,
										color: "#008000",
									},
									{
										name: t(`const.checklist_status.issue`),
										total: issue,
										color: "#D90429",
									},
									{
										name: t(`const.checklist_status.inprogress`),
										total: inprogress,
										color: "#FCA311",
									},
									{
										name: t(`const.checklist_status.completed`),
										total: completed,
										color: "#1894c5",
									},
								],
							};
						})}
					></Unit>
				</div>
			</PngExportable>

			<PngExportable
				title={t(`report_title.checklist_createdby`, { start, end, top: 10 })}
			>
				<div className="relative">
					<Watermark />
					<CreatedBy data={createdby}></CreatedBy>
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.checklist_hour`, { start, end })}>
				<div className="relative">
					<Watermark />
					<Hour
						data={hour}
						renderColor={(value) => {
							if (value < 6) return "bg-def-green text-white";
							if (value > 5 && value < 11) return "bg-def-secondary text-white";
							if (value > 10) return "bg-def-red text-white";
						}}
					/>
				</div>
			</PngExportable>
			<PngExportable
				title={t(`report_title.checklist_location`, { start, end })}
			>
				<div className="relative">
					<Watermark />
					<Table
						data={location}
						tPath="const.checklist_status"
						columns={["normal", "issue", "inprogress", "completed", "total"]}
					/>
				</div>
				<div className="mt-4">
					<Location data={location} />
				</div>
			</PngExportable>
			<div className="relative">
				<Watermark />
				<TableExportable
					title={t(`report_title.checklist_table`, { start, end })}
					find={{ start, end }}
					url="/report/checklist/export"
					data={table}
					columns={[
						{
							label: t("fields.template"),
							render: { type: "string", field: "template.name" },
						},
						{
							label: t("fields.location"),
							render: { type: "string", field: "location.name" },
						},
						{
							label: t("fields.unit"),
							render: { type: "string", field: "unit.name" },
						},
						{
							label: t("fields.createdby"),
							render: ({ createdby }) =>
								`${createdby.firstname} ${createdby.lastname} (${createdby.position})`,
						},
						{
							label: t("checklist.status"),
							render: {
								type: "trans",
								tPath: "const.checklist_status",
								field: "status",
							},
						},
						{
							label: t("checklist.percent"),
							render: { type: "string", field: "percent" },
						},
						{
							label: t("fields.created"),
							render: { type: "date", field: "created" },
						},
					]}
				/>
			</div>
		</div>
	);
};

export default PermissionReport;
